import { Component, OnInit } from '@angular/core';
import { Media, Category } from '../media';
import { MediaService } from '../media.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.less']
})
export class MediasComponent implements OnInit {

  medias: Media[] = [];
  visibleMedias: Media[] = [];
  selectedMedias: Media[] = [];
  lastSelectedMedia: Media;

  categories: String[];
  selectedCategory: String = 'ALL';
  sharePanelMessage = null;

  page: number = 1;
  previewSize: number = 250;
  mediaPreviewWidth = "250px";
  numPreviewsPerPage: number = 25;
  possiblePreviewSizes = ["260px", "290px", "400px", "500px", "600px", "800px", "1000px", "1400px", "100%"];

  constructor(private mediaService: MediaService, private modalService: NgbModal, private spinner: NgxSpinnerService) {  }

  ngOnInit() {
    this.getCategories();
    this.getMedias();
    this.spinner.show();
    this.setPreviewSize();
  }

  setNumPerPage(value: number): void {
    this.numPreviewsPerPage = value;
    this.page = Math.min(this.page, this.medias.length / this.numPreviewsPerPage);
  }
  
  selectCategory(value: string) {
    this.page = 0;
    this.selectedCategory = value;
    this.updateVisibleMedias();
  }
  
  selectMedia(media: Media): void {
    this.lastSelectedMedia = media;
    if (this.selectedMedias.indexOf(media) == -1)
      this.selectedMedias.push(media);
  }
  
  unselectMedia(id: Number) {
    this.selectedMedias = this.selectedMedias.filter(function(item) {
      return item.id != id;
    });
    this.updateMediasMetadata();
  }

  updateMediasMetadata() {
    for (var media in this.medias) {
      //media.selected
    }
  }

  updateVisibleMedias() {
    if (this.selectedCategory.localeCompare("ALL") == 0)
      {this.visibleMedias = this.medias.slice(0); return;}

    var category = this.selectedCategory;
    this.visibleMedias = this.medias.filter(function (media) {
      return media.category === category;
    });
  }

  getMedias(): void {
    var self = this;
    this.mediaService.getMedias()
      .subscribe(function(medias) {
        self.medias = medias;
        self.updateVisibleMedias();
        self.hideLoading();
      });
  }

  getMedia(name: string): void {
    this.mediaService.getMedia(name)
      .subscribe(function(media: Media) {
        //this.lastSelectedMedia = media;
        //this.selectedMedias.push(media);
      });
  }

  getCategories(): void {
    var self = this;
    this.mediaService.getCategories()
      .subscribe(function(categories: String[]) {
        categories.sort();
        //if (categories.length > 0) self.selectedCategory = categories[0];
        categories.splice(0, 0, "ALL");
        //categories.splice(0, 0, "Categories");
        self.categories = categories;
        self.updateVisibleMedias();
      });
  }

  hideLoading(): void {
    this.spinner.hide();
  }

  openModal(content): void {
    this.modalService.open(content);
    var mediaListWidget: any = document.getElementById("mediaList");
    var mediaList = "";
    var sortedMedias = [...this.selectedMedias].sort(function(a,b) {return a.id - b.id});
    for (var media of sortedMedias)
      mediaList += media.category + "    ###    " + media.name + "\n";
    mediaListWidget.value = mediaList;
    this.copyMediaList();
  }

  copyMediaList() : void {
    var copyText: any = document.getElementById("mediaList");
    copyText.select();
    document.execCommand("copy");
    this.setPanelMessage("Media selection copied to clipboard.")
  }
  
  setPanelMessage(message: string) {
    var self = this;
    self.sharePanelMessage = message;
    setTimeout(() => {
      self.sharePanelMessage = null;
      if (window.getSelection)
        window.getSelection().removeAllRanges();
      //else if (document.selection)
      //  document.selection.empty();
    }, 2000);
  }

  clamp(number, min, max) {
    return Math.max(min, Math.min(number, max));
  }

  setPreviewSize(delta: number = 0) {
    if (delta == 0)
      this.previewSize = 3;
    else
      this.previewSize += delta;
    this.previewSize = this.clamp(this.previewSize, 0, this.possiblePreviewSizes.length - 1);
    this.mediaPreviewWidth = this.possiblePreviewSizes[this.previewSize];
  }
}
