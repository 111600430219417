import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Media, Category } from './media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor (private http: HttpClient) { }

  getMedias(): Observable<Media[]> {
    var message = this.http.get<Media[]>('/api/medias');
    return message;
  }

  getMedia(id: string): Observable<Media> {
    return this.http.get<Media>('/api/medias/' + id);
  }

  getCategories(): Observable<String[]> {
    return this.http.get<String[]>('/api/categories/');
  }
}
